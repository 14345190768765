import React from "react"
import Button from "../components/Button"
import { Footer } from "../components/Footer"
import Header from "../components/header"
import Menuoverlay from "../components/menuoverlay"
import Seo from "../components/seo"

const NotFound = () => {
  return (
    <>
      <Seo title="404 Not found" />
      <Header />
      <div className="overflow-x-hidden">
        <div className="bodybg"></div>
        <div className="w-screen max-w-screen-2xl ">
          <div className="flex flex-col mx-4 md:mx-12 lg:mx-32 mt-8 justify-items-center items-center overflow-hidden">
            <div className="mobH1 md:deskH1 textGradient text-center ">
              Error 404!
            </div>
            <div className="mobH3 md:deskH3 textGradient mt-8  text-center">
              Oops! We couldn’t find the page<br></br> you were looking for
            </div>
            <div className="mobH5 md:deskH5 textBlack mt-16  text-center ">
              But look what we found instead — a secret template!
            </div>
            <a
              href="https://app.flexeet.club/templates/transparentflex"
              className="mt-8"
            >
              <Button
                title="Go To Secret Template"
                buttonStyle="btnPrimary"
                buttonSize="btnBig"
                fitType="btnFit"
              />
            </a>
            <a
              href="https://flexeet.club"
              className="mobCTA-HL md:deskCTA-HL textBlack mt-6 mb-20"
            >
              Or Go To Home
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <Menuoverlay />
    </>
  )
}

export default NotFound
